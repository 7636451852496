<template>
  <div v-if="data" class="pagination-bx clearfix">
    <ul class="pagination">
      <li
        @click="handlePrevious"
        :class="'previous' + (hasPrevious() ? '' : ' disabled')"
      >
        <div class="item"><i class="fa fa-angle-double-left"></i></div>
      </li>
      <li
        @click="handleClick(index)"
        :class="isActive(index) ? 'active' : ''"
        v-for="index in getAllVisablePageItems()"
        :key="index"
      >
        <div class="item">{{ index }}</div>
      </li>
      <li
        @click="handleNext()"
        :class="'next' + (hasNext() ? '' : ' disabled')"
      >
        <div class="item"><i class="fa fa-angle-double-right"></i></div>
      </li>
    </ul>
    <div class="info">
      <strong>{{ getTitle() }}</strong> {{ getFrom() }} bis {{ getTo() }} von
      {{ getTotalCount() }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  methods: {
    getAllVisablePageItems() {
      const maxItems = 2;
      const result = [];
      const items = Math.ceil(this.data.meta.filter_count / this.data.limit);
      for (let i = 1; i <= items; i++) {
        if (
          i <= this.currentOffset / this.data.limit + maxItems + 1 &&
          i >= this.currentOffset / this.data.limit - maxItems + 1
        ) {
          result.push(i);
        }
      }
      return result;
    },

    getTitle() {
      return this.data.title ? this.data.title : "Items";
    },

    getTotalCount() {
      return this.data.meta.filter_count;
    },

    handleClick(index) {
      this.currentPage = index;
      this.currentOffset = (index - 1) * this.data.limit;
      this.$emit("paginationData", this.currentOffset);
    },

    isActive(index) {
      return index === this.currentPage;
    },

    hasNext() {
      return (
        this.currentPage <
        Math.ceil(this.data.meta.filter_count / this.data.limit)
      );
    },

    hasPrevious() {
      return this.currentPage > 1;
    },

    handlePrevious() {
      if (this.hasPrevious()) {
        this.currentOffset = this.currentOffset - 1;
        this.currentPage = this.currentPage - 1;
      }
    },

    handleNext() {
      if (this.hasNext(this.data)) {
        this.currentPage = this.currentPage + 1;
        this.currentOffset = (this.currentPage - 1) * this.data.limit;
      }
    },

    getFrom() {
      return this.currentPage === 1
        ? 1
        : this.data.limit * this.currentPage - this.data.limit + 1;
    },
    getTo() {
      return this.getFrom() + this.data.limit - 1 > this.data.meta.filter_count
        ? this.data.meta.filter_count
        : this.getFrom() + this.data.limit - 1;
    },
  },
  data: () => ({
    currentOffset: 0,
    currentPage: 1,
  }),
  props: {
    data: Object,
  },
});
</script>

<style lang="scss" scoped>
@import "../../../public/css/variables.scss";
.pagination-bx {
  border-top: solid 1px $primary;
  padding-top: 10px;
  .info {
    text-align: center;
    text-align: center;
    color: gray;
    text-transform: uppercase;
    font-size: smaller;
  }
  .pagination {
    justify-content: center;
    li {
      margin: 0 2px;
      cursor: pointer;
      &.active > .item {
        background-color: $primary;
        border-color: $primary;
        color: white;
      }
      .item {
        padding: 8px 14px;
        font-size: 12px;
        background-color: #fff;
        border: 1px solid #e0e0e0;
        color: #767676;
        padding: 8px 14px;
        font-weight: 600;
      }
    }
    .next,
    .previous {
      &.disabled {
        opacity: 0.3;
      }
    }
  }
}
</style>
