<template>
  <aside class="side-bar">
    <div v-if="this.isFull === '1'" class="widget">
      <h4 class="widget-title">Suche</h4>
      <div class="search-bx">
        <form role="search" method="post">
          <div class="input-group">
            <input
              name="text"
              type="text"
              class="form-control"
              placeholder="Suche"
              v-model="search"
            />
            <span class="input-group-btn">
              <button type="submit" class="site-button">
                <i class="fa fa-search"></i>
              </button>
            </span>
          </div>
        </form>
      </div>
    </div>
    <div v-if="lastNews.length > 0" class="widget recent-posts-entry">
      <h4 class="widget-title">Aktuelle News</h4>
      <div class="widget-post-bx">
        <div
          :key="item.id"
          v-for="item of lastNews"
          class="widget-post clearfix"
        >
          <router-link :to="'/news/' + item.id">
            <div class="dez-post-media">
              <img
                :src="getImage(item.image, 'x200y160')"
                width="200"
                height="143"
                alt=""
              />
            </div>
          </router-link>
          <div class="dez-post-info">
            <div class="dez-post-header">
              <div class="slug">
                <router-link
                  v-show="item.slug != 'default'"
                  class="text-primary"
                  :to="'/sections/' + item.slug"
                >
                  {{ getTitleBySlug(item.slug, section_settings) }}
                </router-link>
                <a
                  href="#"
                  class="text-primary"
                  v-show="item.slug == 'default'"
                >
                  {{ getTitleBySlug(item.slug, section_settings) }}
                </a>
              </div>
              <h6 class="post-title">{{ item.title }}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="news.length > 0 && this.isFull === '1'"
      class="widget widget_categories"
    >
      <h4 class="widget-title">Sektionen</h4>
      <div :key="item.id" v-for="item of news" class="section-item">
        <label>
          <input
            :disabled="
              checkedFilters.length === 1 && checkedFilters.includes(item.slug)
            "
            v-model="checkedFilters"
            checked="checked"
            :id="item.slug"
            type="checkbox"
            :value="item.slug"
          />
          <label :for="item.slug"
            >{{ getTitleBySlug(item.slug, section_settings) }}
            <span>({{ item.occurrence }})</span></label
          >
        </label>
      </div>
    </div>
  </aside>
</template>

<script>
import { defineComponent, inject } from "vue";
import news from "../../lib/api/news";
import { getImage, findOcc, getTitleBySlug } from "../../lib/helper";
export default defineComponent({
  methods: {
    getImage,
    getTitleBySlug,
    selectAllFilters: function () {
      this.news.map((item) => {
        this.checkedFilters.push(item.slug);
      });
    },
  },
  props: {
    isFull: String,
  },
  data() {
    return {
      lastNews: [],
      news: [],
      checkedFilters: [],
      search: "",
      section_settings: [],
    };
  },
  async mounted() {
    const lastNewsResult = await news.getNews({
      limit: 3,
      sort: "-date_created",
    });

    const newsResult = await news.getNews({
      fields: "slug",
    });

    this.lastNews = lastNewsResult.data;
    this.news = findOcc(newsResult.data, "slug");

    this.selectAllFilters();
  },

  updated() {
    this.$emit("navigationData", {
      filters: this.checkedFilters,
      search: this.search,
    });
  },

  created() {
    this.section_settings = inject("section_settings");
  },
});
</script>

<style lang="scss" scoped>
.dez-post-header {
  display: grid;
}
.dez-post-meta {
  font-size: smaller;
}
.post-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.section-item {
  border-bottom: 1px solid rgba(102, 102, 102, 0.11);
  margin-bottom: 8px;
  padding-bottom: 8px;
  input[type="checkbox"] {
    position: absolute;
  }
  input[type="checkbox"]:checked + label:before {
    border-width: 12px;
  }
  input[type="checkbox"]:disabled + label:before {
    opacity: 0.5;
  }
  label {
    padding-left: 20px !important;
    margin: 0;
    padding: 0;
    top: 0;
    span {
      color: grey;
      font-weight: normal;
    }
    &::before {
      width: 24px;
      height: 24px;
      margin-left: -40px;
      top: 0;
    }
    &::after {
      font-size: 16px !important;
      left: -15px !important;
      top: 0px !important;
    }
  }
}
</style>
