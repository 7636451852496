<template>
  <div class="page-content">
    <!-- inner page banner -->
    <div
      class="dez-bnr-inr overlay-black-middle"
      :style="`background-image: url(${getImage(
        '90ff7b42-d871-48f1-bae5-a905b16a5008',
        'x2000y1200'
      )})`"
    >
      <div class="container">
        <div class="dez-bnr-inr-entry">
          <h1 class="text-white">News</h1>
        </div>
      </div>
    </div>
    <!-- inner page banner END -->
    <!-- Breadcrumb row -->
    <Breadcrumb v-bind:data="[{ label: 'News' }]" />
    <!-- Breadcrumb row END -->
    <div class="section-full content-inner-1">
      <div class="container">
        <div class="row">
          <!-- Side bar start -->
          <div class="col-lg-4">
            <Navigation
              isFull="1"
              v-on:navigationData="navigationDataRecived"
            />
          </div>
          <!-- Side bar END -->
          <!-- Left part start -->
          <div class="col-lg-8 scroll">
            <div class="container" v-if="news.length > 0">
              <div class="clearfixs">
                <!-- blog grid -->
                <div id="masonry" class="row dez-blog-grid-2">
                  <div
                    :key="item.id"
                    v-for="item of news"
                    class="post card-container col-lg-6 col-sm-6 col-12"
                  >
                    <div class="blog-post blog-grid date-style-2">
                      <div class="dez-post-media dez-img-effect zoom-slow">
                        <router-link
                          :to="'/news/' + item.id"
                          rel="bookmark"
                          class="site-button-link"
                        >
                          <img
                            :src="getImage(item.image, 'x700y500')"
                            :alt="item.title"
                          />
                        </router-link>
                      </div>
                      <div class="dez-post-info">
                        <div class="dez-post-title">
                          <h3 class="post-title">
                            <div class="slug">
                              <router-link
                                v-show="item.slug != 'default'"
                                class="text-primary smaller"
                                :to="'/sections/' + item.slug"
                              >
                                {{
                                  getTitleBySlug(item.slug, section_settings)
                                }}
                              </router-link>
                              <a
                                href="#"
                                class="text-primary smaller"
                                v-show="item.slug == 'default'"
                              >
                                {{
                                  getTitleBySlug(item.slug, section_settings)
                                }}
                              </a>
                            </div>
                            <router-link :to="'/news/' + item.id">
                              {{ item.title }}
                            </router-link>
                          </h3>
                        </div>
                        <div class="dez-post-meta">
                          <ul>
                            <li class="post-date">
                              <i class="fa fa-calendar"></i
                              ><strong>{{
                                getDate(item.date_created, "DD MMM")
                              }}</strong>
                              <span>{{
                                getDate(item.date_created, "YYYY")
                              }}</span>
                            </li>
                          </ul>
                        </div>
                        <div
                          class="dez-post-text"
                          v-html="cutString(item.description, 250)"
                        ></div>
                        <div class="dez-post-readmore">
                          <router-link
                            :to="'/news/' + item.id"
                            title="READ MORE"
                            rel="bookmark"
                            class="site-button-link"
                            >MEHR LESEN<i class="fa fa-angle-double-right"></i
                          ></router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- blog grid END -->
                <!-- Pagination start -->
                <Pagination
                  :key="this.pagination.instance"
                  v-bind:data="this.pagination"
                  v-on:paginationData="paginationDataRecived"
                />
                <!-- Pagination END -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, inject } from "vue";
import news from "../lib/api/news";
import {
  getImage,
  cutString,
  userCreatedFullName,
  getTitleBySlug,
  scrollToTop,
  getDate,
} from "../lib/helper";
import Navigation from "../components/news/Navigation.vue";
import Breadcrumb from "../components/misc/Breadcrumb.vue";
import Pagination from "../components/misc/Pagination.vue";
export default defineComponent({
  components: {
    Navigation,
    Breadcrumb,
    Pagination,
  },
  methods: {
    getTitleBySlug,
    getImage,
    cutString,
    userCreatedFullName,
    getDate,
    paginationDataRecived(currentOffset) {
      this.pagination.offset = currentOffset;
      this.fetchNews();
      scrollToTop(".blog-post");
    },
    navigationDataRecived(filters) {
      this.filters = filters.filters;
      this.search = filters.search;
      this.pagination.offset = 0;
      /** @TODO */
      this.pagination.instance++;
      this.fetchNews();
    },
    async fetchNews() {
      let params = {
        limit: this.pagination.limit,
        offset: this.pagination.offset,
        sort: "-date_created",
      };

      if (this.filters.length > 0) {
        params["filter[slug][_in]"] = this.filters;
      }

      if (this.search.length > 0) {
        params["search"] = this.search;
      }

      const result = await news.getNews(params);

      this.news = result.data;
      this.pagination.meta = result.meta;
    },
  },
  data() {
    return {
      news: {},
      pagination: {
        instance: 0,
        limit: 6,
        offset: 0,
        meta: {},
        title: "News",
      },
      filters: [],
      search: "",
      section_settings: [],
    };
  },
  async mounted() {
    await this.fetchNews();
  },
  created() {
    this.section_settings = inject("section_settings");
  },
});
</script>
